.userLine {
  background-color: #e4e5ea;
  margin: 10px;
  text-align: left;
  padding: 5px;
  cursor: pointer;
}

.bold-text {
  font-weight: bold;
}

.red-text {
  color: red;
}

.left-text {
  text-align: start;
}

.checkbox-permissions {
  background-color: #e4e5ea;
  margin: 5px;
  padding: 5px;
}

.checkbox-permissions-label {
  margin: 15px;
}

button:-moz-user-disabled
button:disabled,
button[disabled]
{
  border: 1px solid red;
  background-color: green;
  color: #885566;
}
