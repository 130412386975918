// eslint-disable-next-line
@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.activeRoute {
  font-weight: bold;
  color: red;
}

.spinnerdiv {
  background-color: gray;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.7;
}

// #02315E
// #00457E
// #2F70AF
// #B9848C
// #806491 

// #e2664c
// #d08477
// #e8c4c4
// #e5dade
// #feeff4 

//gray
// #16354D
// #6B99C3
// #D2D2D4
// #E4E5EA
// #0C151C 


