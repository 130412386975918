.formElement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  /* background-color: cyan; */
  width: 100%;
}

.formElement input {
  width: 100%;
}

.inputWrongValue, .inputWrongValue:active, .inputWrongValue:focus{
  border-color: red;
  border-width: 2px;
  outline: none;
}

.inputCorrectValue, inputCorrectValue:focus {
  border-color: green;
  border-width: 2px;
  outline: none;
}

.inputtst:focus, .inputtst:active {
  border:5px solid red;
}

input {
  margin-left: 10px;
  margin-right: 10px;
}