.formLine {
  background-color: #e4e5ea;
  margin: 10px;
  text-align: left;
  padding: 15px;
  cursor: pointer;
  font-size: x-large;
}

.bold-text {
  font-weight: bold;
}

.red-text {
  color: red;
}

.buttonToRight {
  display: flex;
  flex-direction: row-reverse;
}

.buttonSection{
  margin: 10px;
}